import { TableColumn } from "react-data-table-component";

export interface IFilterResult<T> {
    total: number;
    elements: T[];
}

export type IFilterParams<T> = Record<string, T> | {
    _page?: number;
    _limit?: number;
    _sort?: string;
    _order?: 'asc' | 'desc';
    _search?: string;
}

export const basicColumn = (id: string, name: string, wrapped = false, ext: Partial<TableColumn<any>> = {}) => Object.assign({
    id,
    sortable: true,
    name,
    selector: wrapped ? ((row: any) => row.state[id]) : ((row: any) => row[id]),
}, ext) as TableColumn<any>;

export const basicColumnEx = <T>(id: keyof T, name: string, wrapped = false, ext: Partial<TableColumn<any>> = {}) => Object.assign({
    id,
    sortable: true,
    name,
    selector: wrapped ? ((row: any) => row.state[id]) : ((row: any) => row[id]),
}, ext) as TableColumn<any>;
