import { EV } from 'enerx-shared';

function asEnv(hst: string) {
    if (hst.includes('stage')) return EV.EnvTyp.Stage;
    return EV.EnvTyp.Prod;
}

function getEnv() {
    const hst = document.location.hostname;
    if (hst == 'localhost') return EV.EnvTyp.Dev;
    if (hst.endsWith('.wattlogic.com')) return asEnv(hst);
    if (hst.endsWith('.enerx.app')) return asEnv(hst);
    throw new Error();
}
const env: EV.EnvTyp = getEnv()

const googleAnalytic = env == EV.EnvTyp.Dev ? 'UA-270468167-1' : 'GTM-NDFSV3D'; // 'UA-270468167-1' Andriy's one, 'GTM-NDFSV3D' - Danny provided


const sharedCfg = EV.getSharedConfig(env, null, 'web');

// Will be totally replaced with shared config
export const cfg = {
    env,
    apiUrl: sharedCfg.base.ev.api,
    fire: sharedCfg.firebase,
    appUrl: sharedCfg.base.ev.app,
    sfBase: sharedCfg.base.sfdc,
    stripeBase: sharedCfg.base.stripe,
    googleAnalytic,
    cloudOpts: {
        apiBase: sharedCfg.base.ev.api,
        storageCfg: sharedCfg.cdn,
    }
}
