export * from './cfg';
export * from './filter';

export interface ICoverageRow {
    uid: string;
    city: string;
    state: string;
    county: string;
    covered: boolean;
}
